<template>
  <b-modal id="modal-scrollable" size="sm" ref="create-permission" hide-footer title="Create Permission">
    <div class="d-block text-left"></div>
    <div class="row">
      <div class="col-12 mt-4">
        <v-text-field outlined dense label="Permission" v-model="permission_name"></v-text-field>
        <span class="text-danger" v-if="$v.permission_name.$error">Permission name is required</span>
      </div>

      <div class="col-12 text-right mt-5">
        <v-btn
            color="red"
            class="btn btn-standard"
            depressed
            @click="hideModal"
        >Cancel
        </v-btn>
        <v-btn v-if="checkIsAccessible('permission','create')"
               class="text-white ml-2 btn btn-primary"
               depressed
               @click="createPermission"
               :loading="isBusy"
        >Save
        </v-btn>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import PermissionService from "@/core/services/user/permission/PermissionService";

const permissionService = new PermissionService();

export default {
  name: "CreateAndUpdate", validations: {
    permission_name: {required}
  }, data() {
    return {
      permission_name: null,
      isBusy: false
    }
  }, methods: {
    createPermission() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000)
      } else {
        permissionService.store({name: this.permission_name}).then(response => {
          this.hideModal()
          this.permission_name = null;
          this.$emit("update_list");
        })
      }
    }, showModal() {
      this.$refs['create-permission'].show();
    }, hideModal() {
      this.$refs['create-permission'].hide();
    }
  }
}
</script>

<style scoped>

</style>
